import Button from '@components/UI/Button';
import Icon from '@components/UI/Icon';

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ onClose, children }) => {
    const overlay = useRef(null);
    const wrapper = useRef(null);

    const handleCloseClick = (e) => {
        e.preventDefault();
        onClose();
    };

    const onKeyDown = (e) => {
        if (e.key === 'Escape') handleCloseClick(e);
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => document.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    const modalContent = (
        <div
            ref={overlay}
            className="fixed bottom-0 left-0 right-0 top-0 z-[1040] mx-auto bg-neutral-900/50"
            onClick={handleCloseClick}
        >
            <div
                ref={wrapper}
                className="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 p-7 sm:w-10/12 lg:w-[984px]"
            >
                <Button
                    className={'absolute right-0 top-0'}
                    variant='link'
                    onClick={handleCloseClick}
                >
                    <Icon
                        name={'close'}
                        color={['neutral-100']}
                        size={24}
                    />
                </Button>
                {children}
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.getElementById('modal-root')
    );
};

Modal.displayName = 'Modal';

Modal.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func
};

export default Modal;