import Icon from '@components/UI/Icon';
import Modal from '@components/UI/Modal';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const VideoModal = ({ videoUrl, className, iconSize, btnText }) => {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (document) {
            document.body.style.overflow = showModal ? 'hidden' : 'auto'
        }
    }, [showModal]);

    return (
        <>
            <button className={className} onClick={() => setShowModal(true)}>
                <Icon
                    name={ 'playVideo' }
                    color={ ['violet-700', 'neutral-100'] }
                    size={ iconSize }
                />
                { btnText }
            </button>
            {showModal &&
            <Modal onClose={() => setShowModal(false)}>
                <iframe
                    className="w-full aspect-video"
                    loading="lazy"
                    src={ videoUrl }
                    title="Traqq: Time Tracker for on-site and remote teams"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                </iframe>
            </Modal>
            }
        </>
    );
};

VideoModal.displayName = 'VideoModal';

VideoModal.propTypes = {
    videoUrl: PropTypes.string,
    className: PropTypes.string,
    iconSize: PropTypes.number,
    btnText: PropTypes.string,
};

VideoModal.defaultProps = {
    className: '',
    iconSize: 32,
    btnText: ''
};

export default VideoModal;